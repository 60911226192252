import { init, track, identify, Identify } from '@amplitude/analytics-browser';
import { Address } from 'viem';

import { NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID } from '../constants/chain';
import ENV from '../environment';
import { CheckoutModeKeys } from '../hooks/useCheckoutMode';
import { PriceImpact } from '../store/trade';
import { isTest } from './environment';
import { ChainAddress } from './models';

export enum EVENT_NAME {
  // TODO: MAT-3671 old event names - clean these up after migration is done if dj doesn't need them (fb)
  // networks
  NETWORK_CHANGE_REQUESTED = 'Network Change Requested',
  NETWORK_CHANGE_SUCCESSFUL = 'Network Changed',
  NETWORK_CHANGE_FAILED = 'Network Change Failure',
  //transactions (general)
  TXN_DETAILS_ENAGED = 'Opened Txn Details',
  TXN_FLOW_NAVIGATED_BACK = 'Navigated To Previous Txn Step',
  SWITCHED_TO_LIMIT_ORDER_FROM_PI_ALERT = 'Clicked Use Limit Order from Price Impact Alert',
  CONTINUED_SWAP_FROM_PI_ALERT = 'Clicked Continue from Price Impact Alert', // not being used
  INITIATED_NEW_TRADE = 'Initiated New Trade',
  // market orders
  SWAP_PRICE_RESPONSE_ERROR = 'Swap Price Response Error',
  GASLESS_PRICE_ERROR_VIEWED = 'Gasless Price Error Viewed',
  TXN_SUBMITTED = 'Transaction Submitted',
  // swap details event
  TXN_REJECTED = 'Transaction Rejected',
  TXN_FAILED_SUBMIT = 'Transaction Failed To Submit',
  TXN_PENDING = 'Transaction Pending',
  QUOTE_REFRESHED = 'Quote Refreshed',
  GASLESS_APPROVAL_SIGNED = 'Gasless Approval Signed',
  META_TRANSACTION_SIGNED = 'Meta Transaction Signed',
  // limit orders
  LIMIT_ORDER_SUBMITTED = 'Limit Order Submitted',
  LIMIT_ORDER_PENDING = 'Limit Order Pending',
  LIMIT_ORDER_REJECTED = 'Limit Order Rejected',
  // allowances
  TOKEN_ALLOWANCE_APPROVAL = '0xEP Allowance Approval Initiated',
  TOKEN_ALLOWANCE_APPROVAL_ERROR = '0xEP Allowance Approval Failed',
  TOKEN_DESCRIPTION_EXPANDED = 'Token Description Expanded',
  TOKEN_ADDRESS_COPIED = 'Token Address Copied',
  SELL_BALANCE_MAX_50_CLEAR_CLICKED = 'Sell Balance Max/50/Clear Clicked',
  TOKEN_LINK_CLICKED = 'Token Link Clicked',
  CHART_RESOLUTION_CLICKED = 'Chart Resolution Clicked',
  START_TRADING_CLICKED = 'Start Trading Button Clicked',
  // wallets
  WALLET_CONNECTED = 'Wallet Connected',
  WALLET_DISCONNECTED = 'Wallet Disconnected',

  /**
   * New Event Names - must follow snake case
   * Source of Truth https://docs.google.com/spreadsheets/d/12WXJP4f_6ZaPNEOdMzxDq_zOlIFBRu_nSaEtr7JMbSI/edit#gid=0
   *  */

  // transaction module shortcuts
  SELECT_TAKER_AMOUNT_BUTTON = 'select_taker_amount_button',
  SELECT_TAKER_MAKER_FLIP = 'select_taker_maker_flip',

  // transactions
  CLICK_TAB_TRADING_MODULE = 'click_tab_trading_module',
  CLICK_APPROVE_TOKEN = 'click_approve_token',
  CLICK_REVIEW_ORDER = 'click_review_order',
  CLICK_PLACE_ORDER = 'click_place_order',
  CLICK_REFRESH_QUOTE = 'click_refresh_quote',
  TRANSACTION_COMPLETED = 'transaction_completed',
  PRICE_QUOTE_DELTA_EXCEEDED = 'price_quote_delta_exceeded',

  // limit orders
  SELECT_LIMIT_ORDER_EXPIRY = 'select_limit_order_expiry',

  // market orders
  CLICK_ORDER_DETAILS = 'click_order_details',
  CLICK_TO_STANDARD_OR_AUTO = 'click_to_matcha_standard_or_auto',

  // transaction alerts
  PRICE_IMPACT_ALERT_TRIGGERED = 'price_impact_alert_triggered',

  // transaction errors
  TXN_MODULE_ERROR_SCREEN_TRIGGERED = 'txn_error_screen_triggered',

  // routes
  CLICK_TO_EXPAND_ROUTE_DIAGRAM = 'click_to_expand_sankey_diagram',

  // trade history
  CLICK_HISTORY = 'history_clicked',
  HISTORY_DETAIL_MODAL = 'history_detail_modal',

  // search
  SEARCH_BOX_INITIATED = 'search_box_initiated',
  SEARCH_INITIATED = 'search_initiated',
  SEARCH_TOKEN_SYMBOL_SELECTED = 'search_token_symbol_selected',
  SEARCH_SHOW_MORE_TOKENS = 'search_show_more_tokens',

  // additional options - header
  CLICK_CONNECT_WALLET = 'click_connect_wallet',
  CLICK_CONNECT_WALLET_PROFILE = 'click_connect_wallet_profile',
  CLICK_CONNECT_RENAME_WALLET = 'click_connect_rename_wallet',
  CLICK_CONNECT_RENAME_SAVE = 'click_connect_rename_save',
  CLICK_CONNECT_RENAME_RESET = 'click_connect_rename_reset',
  CLICK_CONNECT_COPY_ADDRESS = 'click_connect_copy_address',
  CLICK_CONNECT_DISCONNECT_WALLET = 'click_connect_disconnect_wallet',

  // token page interactions - links
  CLICK_TOKEN_PAGE = 'click_token_page', // template used for links & other actions

  // token page header
  CLICK_SWITCH_TOKEN_BUTTON = 'click_switch_token_button',
  CLICK_COPY_TOKEN_ADDRESS = 'click_copy_token_address',
  CLICK_OFFICIAL_LINKS = 'click_official_links',
  CLICK_EXCHANGE_LISTINGS = 'click_exchange_listings',
  CLICK_SHARE_BUTTON = 'click_share_button',
  CLICK_TOKEN_PAGE_COPY_BUTTON = 'click_token_page_copy_button',

  // home page footer
  CLICK_HELP_GETTING_STARTED = 'click_help_getting_started',
  CLICK_HELP_FAQ = 'click_help_faq',
  CLICK_HELP_REQUEST_FEATURE = 'click_help_request_feature',
  CLICK_HELP_WALLETS = 'click_help_wallets',
  CLICK_HELP_DISCORD = 'click_help_discord',
  CLICK_HELP_TWITTER = 'click_help_twitter',
  CLICK_HELP_WARPCAST = 'click_help_warpcast',
  CLICK_HELP_BLOG = 'click_help_blog',
  CLICK_HELP_PAGE_BOTTOM = 'click_help_page_bottom',
  CLICK_BLOG_PAGE_BOTTOM = 'click_blog_page_bottom',
  CLICK_TERMS_PAGE_BOTTOM = 'click_terms_page_bottom',
  CLICK_PRIVACY_PAGE_BOTTOM = 'click_privacy_page_bottom',
  CLICK_FOOTER_SWAP_TOKENS = 'click_footer_swap_tokens',
  CLICK_FOOTER_LIMIT_ORDERS = 'click_footer_limit_orders',
  CLICK_FOOTER_CROSS_CHAIN = 'click_footer_limit_orders',
  CLICK_FOOTER_COMPANY = 'click_footer_company',
  CLICK_FOOTER_DEVELOPER_APIS = 'click_footer_0x_developer_apis',

  // crosschain landing page
  CLICK_GO_TO_TRADING_BUTTON = 'click_go_to_trading_button',

  // new homepage
  HOMEPAGE_TOKEN_CARD_LOADED = 'token_card_load',
  HOMEPAGE_RECENT_CARD_LOADED = 'recent_card_load',
  HOMEPAGE_CLICK_TOKEN_CARD = 'click_token_card',
  HOMEPAGE_CLICK_RECENT_TRADE = 'click_recent_trade',
  HOMEPAGE_NETWORK_SELECT = 'homepage_network_select',
  HOMEPAGE_START_TRADING = 'start_trading',
  HOMEPAGE_CLICK_PAGINATION = 'homepage_click_pagination',

  // matcha auto repositioning
  CLICK_FEE_INFO_DROPDOWN_MARKET_MODE = 'click_fee_info_dropdown',
  CLICK_FEE_INFO_DROPDOWN_CROSS_CHAIN_MODE = 'click_fee_info_dropdown_cross_chain',
  CLICK_LEARN_MORE = 'click_learn_more',
  CLICK_TRADE_SETTINGS = 'click_trade_settings',
  CLICK_ADVANCED_TRADER_POPUP = 'click_advanced_trader_popup',
  CLICK_ENABLE_MATCHA_AUTO_POPUP = 'click_enable_matcha_auto_popup',

  // trade settings
  NETWORK_COST_SELECTION = 'network_cost_selection',
  SLIPPAGE_SELECTION = 'slippage_selection',
  DYNAMIC_SLIPPAGE_SELECTION = 'dynamic_slippage_selection',
  ROUTE_SELECTION = 'route_selection',

  // feedback collection
  FEEDBACK_BANNER_DISMISSED = 'feedback_banner_dismissed',
  FEEDBACK_BANNER_SENTIMENT_SELECTED = 'feedback_banner_sentiment_selected',
  FEEDBACK_BANNER_TAGS_COMMENT_SUBMITTED = 'feedback_banner_tags_comment_submitted',

  // wallet connection
  CONNECT_WALLET_ERROR = 'connect_wallet_error',
}

type TOKEN_CARD_TYPE = 'trending' | 'popular';
export type HOMEPAGE_CLICK_PAGINATION_PROPS = {
  filter: string;
  paginationDirection: 'left' | 'right';
  tokenCardType: TOKEN_CARD_TYPE;
};

export type HOMEPAGE_NETWORK_SELECT_PROPS = {
  filter: string;
  tokenCardType: TOKEN_CARD_TYPE;
};

export type HOMEPAGE_CLICK_CARD_TYPE = {
  chainId: number;
  tokenAddress: string;
  tokenCardType: TOKEN_CARD_TYPE;
  tokenSymbol: string;
};

export type HOMEPAGE_TOKEN_CARD_LOADED_TYPE = HOMEPAGE_CLICK_CARD_TYPE;

export type TOKENPAGE_BUTTON_PROPS = {
  tokenAddress: string;
  chainName: string;
  chainId: number;
  [key: string]: any;
};

export enum TRANSACTION_MODULE_KEYS {
  MARKET_ORDER = 'market',
  LIMIT_ORDER = 'limit',
  CROSS_CHAIN = 'crossChain',
}

type TxnModuleEventTokenParam = {
  address: ChainAddress;
  chainId: number;
  symbol: string;
};

/**
 * Returns a token pair identifier for the transaction module event.
 *
 * @param sellToken - Sell token info
 * @param buyToken - Buy token info
 */
export const getTokenPair = (
  sellToken?: TxnModuleEventTokenParam,
  buyToken?: TxnModuleEventTokenParam,
) => {
  if (!sellToken || !buyToken) return undefined;

  return sellToken.symbol >= buyToken.symbol
    ? `${NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID[sellToken.chainId]}-${sellToken.symbol}-${
        NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID[buyToken.chainId]
      }-${buyToken.symbol}`
    : `${NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID[buyToken.chainId]}-${buyToken.symbol}-${
        NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID[sellToken.chainId]
      }-${sellToken.symbol}`;
};

const bucketLabels = [
  '0-10',
  '10-100',
  '100-1000',
  '1000-10000',
  '10000-100000',
  '100000-1000000',
  '1000000+',
];
/**
 * Returns a bucket for the USD dollar amount for the transaction module event.
 * This is a special ask from Data team to bucketize the USD dollar amount in amplitude.
 **/
function getUsdDollarAmountBucket(usdDollarAmount: number): string {
  if (usdDollarAmount <= 0) return '0-10'; // handle zero and negative cases
  const { floor, log10, min } = Math;
  const bucketIndex = min(floor(log10(usdDollarAmount)), 6); // cap the index at 6 for $1000000+ bucket

  return bucketLabels[bucketIndex];
}

/**
 * Logs a transaction module event using the sellToken and buyToken to
 * create a tokenPair identifier as requested by the data team.
 *
 * @param eventName - Name of the txn module event
 * @param sellToken - Sell token info
 * @param buyToken - Buy token info
 * @param additionalOptions - Additional options for the event like orderType, expiry, etc.
 */
export function logTxnModuleEvent(
  eventName: EVENT_NAME,
  sellToken?: TxnModuleEventTokenParam,
  buyToken?: TxnModuleEventTokenParam,
  additionalOptions?: {
    sellAmountUsd?: number;
    matchaAutoFeesUsd?: number;
    orderType?: CheckoutModeKeys;
    orderFlowType?: 'auto' | 'standard';
    expiryOption?: string;
    shortcutLabel?: string;
    currentMatchaAutoState?: 'on' | 'off';
    matchaAutoStateClicked?: 'on' | 'off';
    priceImpact?: PriceImpact;
    errorMessage?: string;
    txHash?: string;
    triggerLocation?: string;
    matchaReferrer?: string;
    buyAmountPrice?: string;
    buyAmountQuote?: string;
    gasFromPrice?: string | null;
    gasFromQuote?: string | null;
    deltaPercentage?: number;
    deltaGasPercentage?: number;
  },
) {
  logEvent({
    name: eventName,
    properties: {
      usd_dollar_amount: additionalOptions?.sellAmountUsd,
      usd_dollar_amount_bucket: getUsdDollarAmountBucket(additionalOptions?.sellAmountUsd ?? 0),
      usd_dollar_amount_auto_fees: additionalOptions?.matchaAutoFeesUsd,
      tx_hash: additionalOptions?.txHash,
      taker_token: sellToken?.symbol,
      taker_token_address: sellToken?.address,
      taker_token_chain_id: sellToken?.chainId,
      taker_token_chain_name: sellToken?.chainId
        ? NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID[sellToken.chainId]
        : undefined,
      maker_token: buyToken?.symbol,
      maker_token_address: buyToken?.address,
      maker_token_chain_id: buyToken?.chainId,
      maker_token_chain_name: buyToken?.chainId
        ? NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID[buyToken.chainId]
        : undefined,
      token_pair: getTokenPair(sellToken, buyToken),
      order_flow_type: additionalOptions?.orderFlowType,
      type_of_order: additionalOptions?.orderType,
      expiry_time: additionalOptions?.expiryOption?.replace(/\s+/g, '').toLowerCase(),
      amount: additionalOptions?.shortcutLabel,
      matcha_auto_state_clicked: additionalOptions?.matchaAutoStateClicked,
      matcha_auto_state: additionalOptions?.currentMatchaAutoState,
      price_impact_percentage: additionalOptions?.priceImpact?.percent,
      price_impact_usd: additionalOptions?.priceImpact?.usd,
      error_message: additionalOptions?.errorMessage,
      trigger_location: additionalOptions?.triggerLocation,
      matcha_referrer: additionalOptions?.matchaReferrer,
      buyAmountPrice: additionalOptions?.buyAmountPrice,
      buyAmountQuote: additionalOptions?.buyAmountQuote,
      gasFromPrice: additionalOptions?.gasFromPrice,
      gasFromQuote: additionalOptions?.gasFromQuote,
      deltaPercentage: additionalOptions?.deltaPercentage,
      deltaGasPercentage: additionalOptions?.deltaGasPercentage,
    },
  });
  return;
}

export enum EVENT_TRACKING_KEYS {
  GLOBAL_SEARCH_COMPONENT = 'globalSearch',
  LIMIT_ORDER_COMPONENT = 'limitOrder',
  MARKET_ORDER_REVIEW = 'marketOrderReviewScreen',
  LIMIT_ORDER_REVIEW = 'limitOrderReviewScreen',
  CROSS_CHAIN_ORDER_REVIEW = 'crossChainOrderReviewScreen',
  PRICE_IMPACT_ALERTS = 'priceImpactAlertScreen',
  MARKET_ORDER_ERRORS = 'marketOrderErrorScreen',
  PENDING_TXN_COMPONENT = 'pendingTransactionScreen',
  COMPLETED_TXN_COMPONENT = 'completedTransactionScreen',
  FAILED_TXN_COMPONENT = 'failedTransactionScreen',
  CANCELLED_TXN_COMPONENT = 'cancelledTransactionScreen',
  UNDERPRICED_TXN_COMPONENT = 'underpricedTransactionScreen',
  CATCH_ALL_ERROR_COMPONENT = 'catchAllErrorScreen',
  MARKET_ORDER_COMPONENT = 'marketOrder',
  NO_QUERIES_MADE_SEARCH_RESULTS = 'noQueriesMade',
  TOKEN_PAGE_HEADER = 'tokenPageHeader',
  TRANSACTION_MODULE = 'transactionModule',
  // new event tracking keys
  TOKEN_PAGE_LINKS_SECTION_TOP = 'top_right',
  TOKEN_PAGE_LINKS_SECTION_BOTTOM = 'bottom_left',
  // TODO: MAT-3671 new keys - replace after merge is done (fb)
  MARKET_ORDER = 'market',
  LIMIT_ORDER = 'limit',
}

/**
 * Custom User Properties - Set of addresses used and nwtworks connected to per device ID.
 */
export enum USER_PROPERTIES {
  WALLETS_USED = 'Wallet Addresses Used',
  CHAIN_SELECTED = 'Chain Selected',
  CHAIN_SELECTED_NAME = 'Chain Name',
  ADDRESS_CONNECTED = 'Address Connected',
  WALLET_CONNECTED = 'Wallet Connected',
  SOLANA_WALLET_CONNECTED = 'Solana Wallet Connected',
  SOLANA_ADDRESS_CONNECTED = 'Solana Address Connected',
}

/**
 * Identify Types
 * https://www.docs.developers.amplitude.com/data/sdks/typescript-browser/#user-properties
 */
export enum IDENTIFY_TYPES {
  SET_ONCE = 'setOnce',
  SET = 'set',
  ADD = 'add',
  PREPEND = 'prepend',
  APPEND = 'append',
  REMOVE = 'remove',
  PRE_INSERT = 'preInsert',
  POST_INSERT = 'postInsert',
}

/**
 * Search Types
 */
export enum SEARCH_TYPES {
  HOME_PAGE = 'home_page',
  TOKEN_PAGE_TOP = 'token_page_top',
  TAKER_TOKEN = 'taker_token',
  MAKER_TOKEN = 'maker_token',
}

export const initializeAmplitudeAnalytics = async () => {
  const API_KEY = ENV.AMPLITUDE_API_KEY;
  if (API_KEY !== undefined) {
    init(API_KEY, undefined, {
      serverUrl: '/api/report',
      defaultTracking: {
        attribution: true,
        pageViews: { trackHistoryChanges: 'all' },
        sessions: true,
        formInteractions: false,
        fileDownloads: false,
      },
      flushIntervalMillis: 3000,
    });

    // we need to add the darkmode user properties as soon as possible
    const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
    const theme = document.body.dataset['theme'];
    if (!theme) {
      console.log('Theme not set');
    }
    if (theme === 'light') {
      identifyUserProperties({
        type: IDENTIFY_TYPES.SET,
        key: 'theme_toggle_position',
        value: 'light',
      });
    } else if (theme === 'dark') {
      identifyUserProperties({
        type: IDENTIFY_TYPES.SET,
        key: 'theme_toggle_position',
        value: 'dark',
      });
    } else if (theme === 'system') {
      identifyUserProperties({
        type: IDENTIFY_TYPES.SET,
        key: 'theme_toggle_position',
        value: isDarkMode.matches ? 'dark' : 'light',
      });
    }
    init(API_KEY, undefined, {
      serverUrl: '/api/report',
      defaultTracking: {
        attribution: true,
        pageViews: { trackHistoryChanges: 'all' },
        sessions: true,
        formInteractions: false,
        fileDownloads: false,
      },
      flushIntervalMillis: 3000,
    });
  }
};

export const identifyUserProperties = ({
  type,
  key,
  value = '',
}: {
  type: IDENTIFY_TYPES;
  key: string;
  value?: string | Address;
}) => {
  const identifyObj = new Identify();
  if (type === IDENTIFY_TYPES.ADD) {
    identifyObj.add(key, parseFloat(value));
  } else {
    identifyObj[type](key, value);
  }
  identify(identifyObj);
  return;
};

export interface AmplitudeEvent {
  /* EVENT_NAME enum in amplitude.ts will provide options.*/
  name: string;
  /* Any set of Properties to pass in with the event. They should be consistent, view supporting docs. */
  properties?: any;
}

/**
 * Sends a user event to Amplitude browser SDK.
 * @param props - Object with the name and properties for the event
 */
export function logEvent({ name, properties = {} }: AmplitudeEvent) {
  if (!isTest && typeof window === 'undefined') {
    console.warn(
      `Attempting to log an amplitude event on the server using the browser sdk. event will be ignored: ${name}`,
    );
  }

  return track(name, properties);
}

type EventPosition = 'before' | 'after';

/**
 * A higher order function to log amplitude events.
 * @param callback - The users callback function to invoke.
 * @param event - An event object that gets sent to amplitude.
 * @param position - Whether the event is sent before or after the callback (defaults to 'before').
 */
export const withTracking = (
  callback: () => void,
  event: AmplitudeEvent,
  position: EventPosition = 'before',
) => {
  if (position === 'before') logEvent(event);
  callback();
  if (position === 'after') logEvent(event);
};
