var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1e1ac4d4c2213b68da13934192fa54740f3b5c85"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/nextjs';
import { fullStoryIntegration } from '@sentry/fullstory';
import baseConfig from 'sentry.base.config';

import { isProd } from '@/utils/environment';

// initialize FullStory before Sentry
FullStory.init({
  orgId: 'o-1JG1D7-na1',
  devMode: !isProd,
});

Sentry.init({
  ...baseConfig,
  integrations: [
    Sentry.browserTracingIntegration(),
    fullStoryIntegration('0x-f3', { client: FullStory }),
  ],
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // produced by rabby wallet on windows https://0x-f3.sentry.io/issues/4591794883
    "'defineProperty' on proxy: trap returned falsish for property 'request'",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
    // Avast extension error
    '_avast_submit',
    // contract.symbol() fails on bytes32
    'method="symbol()"',
  ],
  beforeSend(event) {
    // Filter events when the user cancels the wallet connection
    if (event.extra && JSON.stringify(event.extra).includes('User rejected the request')) {
      return null;
    }
    return event;
  },
  allowUrls: [
    /https?:\/\/([a-z0-9]+[.])*matcha\.xyz/,
    /https:\/\/[a-zA-Z0-9-]+\.ingest\.sentry\.io/,
  ],
  denyUrls: [
    // Google Adsense
    /pagead\/js/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
});
